<template>
  <div>
    <b-table-simple
      bordered
      responsive
    >
      <b-tr>
        <b-td>Name</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>POC</b-td>
        <b-td>
          <b-badge
            v-b-modal.founderDetails
            pill
            variant="primary"
            class="mr-1"
          >
            POC Name
          </b-badge>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Phone</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Email</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Website</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>LinkedIn Profile</b-td>
        <b-td />
      </b-tr>
      <b-tr>
        <b-td>Facebook Profile</b-td>
        <b-td />
      </b-tr>
    </b-table-simple>
    <b-modal
      id="founderDetails"
      hide-footer
      title="Founder Details"
    >
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Name</b-td>
          <b-td />
        </b-tr>
        <b-tr>
          <b-td>Designation</b-td>
          <b-td />
        </b-tr>
        <b-tr>
          <b-td>Phone</b-td>
          <b-td />
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td />
        </b-tr>
        <b-tr>
          <b-td>DIN</b-td>
          <b-td />
        </b-tr>
        <b-tr>
          <b-td>Equity Holding</b-td>
          <b-td />
        </b-tr>
      </b-table-simple>

    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'

import gql from 'graphql-tag'

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BBadge,
  },
  data() {
    return {
      partner: {},
    }
  },
  methods: {
    prependHttps(url) {
      if (!url) return ''
      if (url.startsWith('http')) return url
      return `https://${url}`
    },
  },
  apollo: {
    partner: {
      query() {
        return gql`
           query about_partner {
           users_organizationtable(where: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}) {
                registered_title
                startups_founderstables{
                  name
                  phone
                  designation
                  email
                  din_number
                  equity_holding
                }
                industry
                city
                state
                startup_stage
                url
                cin
                dpiit
                official_email
                official_phone
              }
        }`
      },
      update: data => data.users_organizationtable[0],
    },
  },
}
</script>
